import "./App.css";

import React, { useEffect } from "react";
import Typist from "react-typist";

function App() {
  useEffect(() => {
    const slider = document.querySelector(".ContentHorizontal");
    let mouseDown = false;
    let startX, scrollLeft;

    let startDragging = function (e) {
      mouseDown = true;
      startX = e.pageX - slider.offsetLeft;
      scrollLeft = slider.scrollLeft;
    };
    let stopDragging = function (event) {
      mouseDown = false;
    };

    slider.addEventListener("mousemove", (e) => {
      e.preventDefault();
      if (!mouseDown) {
        return;
      }
      const x = e.pageX - slider.offsetLeft;
      const scroll = x - startX;
      slider.scrollLeft = scrollLeft - scroll;
    });

    // Add the event listeners
    slider.addEventListener("mousedown", startDragging, false);
    slider.addEventListener("mouseup", stopDragging, false);
    slider.addEventListener("mouseleave", stopDragging, false);
  });

  return (
    <div className="App">
      <div className="Warning">
        <img src="rotate.png" alt="rotate" /> <br />
        <span style={{ color: "white" }}>Rotate! Rotate! Rotate!</span>
      </div>

      <div className="SideNav">•</div>
      <div className="RightContainer">
        <div className="HomeContentName_Nav">
          {" "}
          ⭐{" "}
          <a href="Jacob_Darvin__Resume_2023.pdf" target="_blank">
            Resume
          </a>{" "}
          |{" "}
          <a
            href="https://www.linkedin.com/in/jacob-darvin/"
            target="_blank"
            rel="noreferrer"
          >
            LinkedIn
          </a>
        </div>
        <div className="Right" id="style-3">
          <div className="Home">
            <div className="HomeContentName">
              <div
                style={{
                  display: "flex",
                  justifyContent: "right",
                  alignItems: "center",
                }}
              >
                <div className="HomeContentName_Main">
                  Jacob
                  <br /> Darvin
                  <div className="HomeContentName_Sub">
                    Lead Software Engineer @ GCash
                    <br />
                    <br />
                  </div>
                </div>
              </div>
            </div>
            <div className="HomeContentOpener">
              <div className="HomeContentOpener_Main">
                <Typist>
                  design-focused.
                  <Typist.Backspace count={8} delay={500} />
                  oriented <br /> development.
                </Typist>
                <div className="HomeContentOpener_Sub">Founder, maybe.</div>
              </div>
            </div>
          </div>

          <div className="Content">
            <div className="Content_Sub">
              <b style={{ fontSize: "1.5em" }}>🎓 Education</b>
              <br />
              De La Salle University Manila (2022)
              <br />
              <br />
              <b style={{ fontSize: "1.5em" }}>💽 Degree</b>
              <br />
              Bachelor of Science in <b>Computer Science</b>, Major in{" "}
              <b>Software Technology</b>
              <br />
              Honorable Mention (3.4 GPA)
              <br />
              <br />
              <b style={{ fontSize: "1.5em" }}>💼 LinkedIn</b>
              <br />
              <a
                href="https://www.linkedin.com/in/jacob-darvin/"
                target="_blank"
                rel="noreferrer"
              >
                linkedin.com/in/jacob-darvin/
              </a>{" "}
              <br />
              <br />
              <b style={{ fontSize: "1.5em" }}>📥 Email</b>
              <br />
              <a href="mailto:jacobisdarvin@gmail.com">
                {" "}
                jacobisdarvin@gmail.com{" "}
              </a>{" "}
              <br />
              <br />
              <b style={{ fontSize: "1.5em" }}>📱 Contact</b>
              <br />
              +63 9176565612
              <br />
            </div>
            <div className="Content_Picture" style={{ position: "relative" }}>
              <div style={{ position: "relative", zIndex: 3 }}>
                <span
                  style={{ fontSize: "3em", fontWeight: 800, zIndex: 2 }}
                  className="text-shadow-custom"
                >
                  <a
                    className="developers"
                    href="https://chat.openai.com/"
                    target="_blank"
                    rel="noreferrer"
                  >
                    I'M A <br />
                    DEVELOPER! <br />
                  </a>
                </span>
                <span
                  style={{ fontSize: "0.6em" }}
                  className="text-shadow-custom"
                >
                  I specialize in cloud services like <b>AWS & Supabase</b>,
                  modern web stacks such as the <b>T3 Stack</b>. I'm well versed
                  in <b>Next.js</b> and <b>TypeScript</b>, with a versatile
                  grasp of languages like <b>Java</b> and <b>C++</b>. My diverse
                  skills ensure the delivery of efficient, scalable applications
                  across the full development spectrum.
                  <br /> <br />
                  <a
                    className="awscertified"
                    href="https://www.credly.com/badges/af580dd5-d095-4ca6-b142-fa5e9a889263/linked_in_profile"
                    target="_blank"
                    rel="noreferrer"
                  >
                    And I'm also an AWS Certified Solutions Architect –
                    Associate!{" "}
                  </a>
                </span>
              </div>
              <img
                src="https://images.credly.com/size/340x340/images/0e284c3f-5164-4b21-8660-0d84737941bc/image.png"
                alt=""
                style={{
                  position: "absolute",
                  right: 0,
                  top: "0%",
                  transform: "translateY(-50%)",
                  zIndex: 0,
                  width: "8em",
                }}
              ></img>
            </div>
          </div>

          <div className="Content">
            <div className="ContentSelection_Sub">
              <b style={{ fontSize: "3em" }}>PROJECTS</b>
              {/* <span style={{ fontSize: 18 }}> <br />
                                <i>I'm still working to get some of these projects in a publicly running state. If the link has a <strike>strike</strike> then it's down for the meantime.</i>
                            </span> */}
            </div>
          </div>
          <div className="ContentHorizontal" id="style-2">
            <div className="ContentSelection_NewBlack HorizontalContainer">
              <div className="content">
                <span className="header">
                  <b>✨ StayKay</b> • Founder
                </span>
                <br />
                <span className="header-body">
                  StayKay's CEO & CTO.
                  <br />
                  Created a fully functional full-stack application for guests
                  and hosts to seamlessly list, share and book stays and experiences
                  with a unique twist. Launching in early 2025.
                </span>
              </div>
              <div className="links"></div>
            </div>
            <div className="ContentSelection_Red HorizontalContainer">
              <div className="content">
                <span className="header">
                  <b>🗺️ RIESGO</b> • Dev <br />
                </span>
                <span className="header-body">
                  My undergraduate thesis. Built with Python and ReactJS, RIESGO
                  is a flood model visualization tool built to understand the
                  flood hazard levels of areas in Metro Manila.
                  <br />
                </span>
              </div>
              <div className="links">
                <a
                  href="https://github.com/G-Justin/Riesgo-2"
                  target="_blank"
                  rel="noreferrer"
                >
                  GitHub
                </a>{" "}
                •{" "}
                <a
                  href="https://riesgo.vercel.app/"
                  target="_blank"
                  rel="noreferrer"
                >
                  Link
                </a>
              </div>
            </div>
            <div className="ContentSelection_Yellow HorizontalContainer">
              <div className="content">
                <span className="header">
                  <b>🚈 UChan</b> • Dev <br />
                </span>
                <span className="header-body">
                  An university imageboard which features image uploading,
                  reCaptcha, commenting, tagging and moderator tools. <br />
                </span>
              </div>
              <div className="links">
                <a
                  href="https://github.com/Darvvvin/UChan"
                  target="_blank"
                  rel="noreferrer"
                >
                  GitHub
                </a>{" "}
                • <strike>Link</strike>
              </div>
            </div>
            <div className="ContentSelection_Blue HorizontalContainer">
              <div className="content">
                <span className="header">
                  <b>📱 Kaha</b> • Android Dev <br />
                </span>
                <span className="header-body">
                  A storage marketplace Android application built for my Mobile
                  Development Class. Features searching, uploading, posts,
                  accounts and server-based storage. <br />
                </span>
              </div>
              <div className="links">
                <a
                  href="https://github.com/matthews-code/Kaha"
                  target="_blank"
                  rel="noreferrer"
                >
                  GitHub
                </a>{" "}
                • <strike>Link</strike>
              </div>
            </div>
            <div className="ContentSelection_Decimal64 HorizontalContainer">
              <div className="content">
                <span className="header">
                  <b>🧮 D64 Convert!</b> • Dev <br />
                </span>
                <span className="header-body">
                  The only Decimal-64 Converter You'll Ever Need. Built (to
                  cheat?) with ReactJS.
                  <br />
                </span>
              </div>
              <div className="links">
                <a
                  href="https://github.com/Darvvvin/csarch2-mp"
                  target="_blank"
                  rel="noreferrer"
                >
                  GitHub
                </a>{" "}
                •{" "}
                <a
                  href="https://decimal-64-converter.vercel.app/"
                  target="_blank"
                  rel="noreferrer"
                >
                  Link
                </a>
              </div>
            </div>
            <div className="ContentSelection_Green HorizontalContainer">
              <div className="content">
                <span className="header">
                  <b>🪥 BookMe Dental</b> • Dev <br />
                </span>
                <span className="header-body">
                  Led and contributed to a full-stack Development team in
                  building a working booking application for a client residing
                  in California, USA. <br />
                </span>
              </div>
              <div className="links">
                <a
                  href="https://github.com/Darvvvin/cssweng_dental"
                  target="_blank"
                  rel="noreferrer"
                >
                  GitHub
                </a>{" "}
                • <strike>Link</strike>
              </div>
            </div>
          </div>

          <div className="Content BorderTop">
            <div className="ContentSelection_Sub">
              <b style={{ fontSize: "3em" }}>EXPERIENCES</b>
            </div>
          </div>

          <div className="Content">
            <div
              className="ContentSelection_Black"
              style={{ paddingBottom: "5em" }}
            >
              <b>GCash</b> • Lead Software Engineer (2023-)
              <br />
              <span style={{ fontSize: "0.6em" }}>
                Developer, Engineer, Architect.
              </span>
            </div>
            <div className="ContentSelection_Black NBlack">
              <b>Accenture</b> • Intern (2023)
              <br />
              <span style={{ fontSize: "0.6em" }}>
                Responsible for managing and developing software solutions for
                Accenture's Security & Identity Cross Organization.
              </span>
            </div>
          </div>

          <div className="Content">
            <div
              className="ContentSelection_Black"
              style={{ paddingBottom: "5em" }}
            >
              <b>Creating Info</b> • Intern (2022)
              <br />
              <span style={{ fontSize: "0.6em" }}>
                Interned as a web-developer Zayls
              </span>
            </div>
            <div className="ContentSelection_Black NBlack">
              <b>COMET</b> • Researcher (2021 - 2022)
              <br />
              <span style={{ fontSize: "0.6em" }}>
                Worked with COMET DLSU to develop RIESGO, a React
                web-application utilizing a model-based approach for analyzing
                the flood risk of areas in Metro Manila.
              </span>
            </div>
          </div>

          <div className="Content">
            <div className="ContentSelection_Sub">
              <b style={{ fontSize: "3em" }}>PROJECT IMAGES</b>
            </div>
          </div>

          <div className="Content-design" style={{ padding: "1em" }}>
            <img
              src="https://i.imgur.com/Uoj0Niz.png"
              style={{ height: "auto", width: "100%" }}
              alt="StayKay"
            />
            <img
              src="https://i.imgur.com/CvOkZP1.png"
              style={{ height: "auto", width: "100%" }}
              alt=""
            />
            <img
              src="https://i.imgur.com/S0TYI4W.png"
              style={{ height: "auto", width: "100%" }}
              alt=""
            />
            <img
              src="https://i.imgur.com/trWWdpF.png"
              style={{ height: "auto", width: "100%" }}
              alt=""
            />
            <img
              src="https://i.imgur.com/KV6lUK7.png"
              style={{ height: "auto", width: "100%" }}
              alt=""
            />
          </div>

          <div
            className="Content"
            style={{
              padding: "1em",
              gap: "1em",
              alignItems: "center",
              height: "12em",
            }}
          >
            <img
              className="MeImage"
              src="https://i.imgur.com/RCmG1mj.jpeg"
              alt="me"
              style={{
                boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.5)", // Added light shadow
              }}
            />
            <span>
              That's the bulk of me! <br /> Thanks for chillin' 😎
            </span>
          </div>

          <div
            style={{
              paddingTop: "1em",
              paddingBottom: "2.5em",
              textAlign: "center",
            }}
          >
            <div>
              <a
                href="https://github.com/Darvvvin"
                target="_blank"
                rel="noreferrer"
              >
                GitHub
              </a>{" "}
              •{" "}
              <a
                href="https://www.linkedin.com/in/jacob-darvin/"
                target="_blank"
                rel="noreferrer"
              >
                LinkedIn
              </a>{" "}
              • © 2024 Jacob Darvin <br />
            </div>
            <div style={{ padding: "5px", fontSize: "0.5em" }}></div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default App;
